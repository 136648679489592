export default class MapSVG {
    constructor(selector = '.continent') {
        console.log('MapSVG initialisé');
        this.selector = selector;
        this.zones = document.querySelectorAll(this.selector);
        this.init();
    }

    init() {
        this.addEventListeners();
    }

    addEventListeners() {
        this.zones.forEach(zone => {
            zone.addEventListener('mouseover', this.handleMouseOver);
            zone.addEventListener('mouseout', this.handleMouseOut);

            zone.addEventListener('click', this.handleClick);

            zone.addEventListener('touchstart', this.handleTouchStart, { passive: true });
            zone.addEventListener('touchend', this.handleTouchEnd, { passive: true });
        });
    }

    handleMouseOver(event) {
        event.target.style.fill = '#8cfbf7';
    }

    handleMouseOut(event) {
        event.target.style.fill = '';
    }

    handleClick(event) {
        const href = event.currentTarget.dataset.href;
        if (href) {
            window.location.href = href;
        }
    }

    handleTouchStart(event) {
        event.target.style.fill = '#8cfbf7';
    }

    handleTouchEnd(event) {
        setTimeout(() => {
            event.target.style.fill = '';
        }, 500);
    }
}

